<template>
  <div class="tw-w-full tw-mb-6">
    <div class="tw-border tw-border-blue-500 tw-rounded-lg tw-overflow-hidden">
      <div
        class="tw-py-4 tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-300"
      >
        <div class="tw-pl-5" data-cy="allowance-name">
          {{ allowance.allowance_type.name }}
        </div>
        <div class="tw-flex tw-items-center tw-justify-end tw-pr-3">
          <div
            class="tw-border-r tw-border-gray-300 tw-pr-3"
            data-cy="allowance-value"
          >
            <div v-if="!is_unlimited">
              <span>{{ totalAllowancesInUnits }} {{ allowanceUnit }}</span>
            </div>
            <div v-if="is_unlimited" class="tw-flex tw-items-center">
              <SvgIcon
                name="infinity"
                class="tw-w-6 tw-h-4 tw-text-blue-500 svg-icon tw-mr-2"
              />
              Unlimited
            </div>
          </div>
          <button
            class="tw-ml-2 btn btn-icon"
            title="Close"
            @click="$emit('close')"
          >
            <div class="tw-flex tw-items-center tw-justify-center">
              <SvgIcon
                name="close"
                class="svg-icon tw-text-gray-600"
                style="width:16px; height:16px;"
              />
            </div>
          </button>
        </div>
      </div>

      <div class="tw-px-5 tw-py-4">
        <div
          :class="{ 'tw-flex tw-items-center tw-justify-center': is_unlimited }"
          style="min-height: 125px;"
        >
          <div v-if="!is_unlimited">
            <LeaveAllowanceBreakdownInput
              v-for="breakdown in allowanceBreakdowns"
              :key="breakdown.id"
              v-model="allowanceBreakdowns"
              :breakdown="breakdown"
              :allowance-unit="allowanceUnit"
              :minutes-per-working-day="minutesPerWorkingDay"
              @lock-breakdown="lockBreakdown"
              @update-breakdown="$emit('update-breakdown', $event)"
              @remove-proration="$emit('remove-proration', $event)"
              @change-value="key => $emit('change-value', key)"
            />
          </div>
          <div v-if="is_unlimited" class="tw-flex tw-flex-col tw-items-center">
            <div class="tw-text-center tw-mb-5">
              <img
                src="./../../assets/img/calendar-success.png"
                style="width: 36px; height: 36px;"
              />
            </div>
            <div class="tw-text-center">
              {{ employment.first_name }} has unlimited allowance.
            </div>
          </div>
        </div>

        <div
          class="tw-mb-3 tw-mt-4 tw-flex tw-items-center tw-text-sm"
          style="color: #8F9CB2;"
        >
          <SvgIcon
            name="info-circle"
            class="svg-icon tw-mr-2"
            style="width: 10px; height: 10px;"
          />
          Additional Options
        </div>

        <div class="tw-flex tw-items-center tw-space-x-4 tw-mb-2">
          <div
            class="tw-flex-1 tw-px-4 tw-py-4 tw-bg-gray-200 tw-rounded-lg tw-flex tw-items-center tw-justify-between"
          >
            <div class="tw-flex tw-items-center">
              <SvgIcon
                name="infinity"
                class="tw-w-6 tw-h-6 tw-text-blue-500 svg-icon tw-mr-3"
              />
              Set as Unlimited
            </div>
            <div>
              <ToggleButton
                :value="is_unlimited"
                :sync="true"
                :labels="false"
                :width="38"
                :height="18"
                color="#1da1f2"
                data-cy="set-as-unlimited"
                @change="toggleUnlimitedState($event.value)"
              />
            </div>
          </div>
          <div class="tw-flex-1">
            <div
              v-if="!is_unlimited && hasEnabledRecalculation"
              class="tw-px-4 tw-py-4 tw-bg-gray-200 tw-rounded-lg tw-flex tw-items-center tw-justify-start"
            >
              <SvgIcon
                name="refresh"
                class="tw-w-6 tw-h-6 tw-text-blue-500 svg-icon tw-mr-3"
                :style="{
                  animation: is_recalculating
                    ? 'rotation 0.5s infinite linear'
                    : 'none',
                }"
              />
              <button
                class="btn-link"
                type="button"
                :disabled="loading || is_recalculating"
                data-cy="btn-calculate-carryover"
                @click.stop="recalculateCarryOverPolicy"
              >
                {{ recalculationButtonText }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy, orderBy } from 'lodash-es'
import { Allowances } from '@/api'
import FormatNumber from '@/mixins/FormatNumbers'
import { ToggleButton } from 'vue-js-toggle-button'
import LeaveAllowanceBreakdownInput from '@/components/employee-leave-allowance/LeaveAllowanceBreakdownInput'

export default {
  name: 'LeaveAllowanceGroupInput',

  components: { LeaveAllowanceBreakdownInput, ToggleButton },

  mixins: [FormatNumber],

  props: {
    value: {
      type: Array,
      required: true,
    },

    allowance: {
      type: Object,
      required: true,
    },

    employment: {
      type: Object,
      required: true,
    },

    hideCarriedToNextAllowance: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      is_unlimited: false,
      is_recalculating: false,
    }
  },

  computed: {
    allowanceState() {
      if (this.is_unlimited) {
        return 'Set allowance'
      }

      return 'Set as unlimited'
    },

    allowanceBreakdowns: {
      get() {
        return orderBy(
          this.allowanceBreakdownSummary,
          [
            'allowance_breakdown_type.policy_settings.type',
            'allowance_breakdown_type.name',
            'should_deduct',
            'carried_over_to',
          ],
          ['asc', 'asc', 'asc', 'desc']
        )
      },
      set(value) {
        this.$emit(
          'input',
          sortBy(
            [
              ...this.otherAllowances,
              {
                ...this.allowance,
                breakdowns: [...value],
              },
            ],
            'allowance_type.name'
          )
        )
      },
    },

    minutesPerWorkingDay() {
      return this.allowance.minutes_per_working_day
    },

    otherAllowances() {
      return this.value.filter(allowance => {
        return allowance.id !== this.allowance.id
      })
    },

    totalAllowances() {
      return this.allowanceBreakdowns
        .map(item => {
          return parseFloat(item.allowance_in_minutes) || 0
        })
        .reduce((total, current) => total + current, 0)
    },

    totalAllowancesInUnits() {
      let totalAllowance = null

      if (this.allowanceUnitIsDays) {
        totalAllowance = this.totalAllowances / this.minutesPerWorkingDay
      } else {
        totalAllowance = this.totalAllowances / 60
      }

      return this.toFixed(totalAllowance, 2)
    },

    allowanceUnitIsDays() {
      return this.allowance.allowance_unit_is_days
    },

    allowanceUnit() {
      return this.allowanceUnitIsDays ? 'days' : 'hours'
    },

    hasEnabledRecalculation() {
      return this.hasCarryOverPolicy && !this.hasLockedCarryOverBreakdowns
    },

    carryOverBreakdowns() {
      const carryOverBreakdown = this.allowance.allowance_type.breakdown_types.find(
        breakdown => breakdown.policy_settings.type === 2
      )

      return this.allowanceBreakdowns.filter(
        breakdown =>
          !breakdown.should_deduct &&
          !breakdown.carried_over_to &&
          breakdown.allowance_breakdown_type_id === carryOverBreakdown.id
      )
    },

    hasCarryOverPolicy() {
      return this.allowance.allowance_type.breakdown_types.some(
        breakdown => breakdown.policy_settings.type === 2
      )
    },

    hasLockedCarryOverBreakdowns() {
      return this.carryOverBreakdowns.some(breakdown => breakdown.is_locked)
    },

    hasCarryOverBreakdown() {
      return this.carryOverBreakdowns.length > 0
    },

    recalculationButtonText() {
      return this.hasCarryOverBreakdown
        ? 'Recalculate Carry Over'
        : 'Calculate Carry Over'
    },

    allowanceBreakdownSummary() {
      if (this.hideCarriedToNextAllowance) {
        return this.allowance.breakdowns.filter(breakdown => {
          return !breakdown.carried_over_to
        })
      }
      return this.allowance.breakdowns
    },
  },

  created() {
    this.is_unlimited = this.allowance.is_unlimited
  },

  methods: {
    async toggleUnlimitedState(value) {
      this.loading = true

      value
        ? await this.enableUnlimitedEmploymentAllowance()
        : await this.disableUnlimitedEmploymentAllowance()

      this.is_unlimited = value

      const status = value ? 'enabled' : 'disabled'
      this.success(`Unlimited allowance ${status} successfully.`)

      this.$emit('is-unlimited-changed')

      this.loading = false
    },

    async enableUnlimitedEmploymentAllowance() {
      return this.$http.post('unlimited-employment-allowances', {
        company_id: this.employment.company_id,
        employment_allowance_id: this.allowance.id,
      })
    },

    async disableUnlimitedEmploymentAllowance() {
      return this.$http.delete(
        `unlimited-employment-allowances/${this.allowance.id}`,
        {
          data: { company_id: this.activeCompany.id },
        }
      )
    },

    async recalculateCarryOverPolicy() {
      if (this.hasLockedCarryOverBreakdowns) {
        this.error(
          'It is not possible to recalculate the carry over when it is locked. ' +
            'Please unlock the carry over to recalculate.'
        )
        return
      }

      this.is_recalculating = true

      try {
        await Allowances.recalculateCarryover({
          company_id: this.employment.company_id,
          calendar_id: this.allowance.calendar_id,
          allowance_type_id: this.allowance.allowance_type_id,
          employment_id: this.employment.id,
        })

        this.success('Carry over has been recalculated.')

        this.$emit('employment-allowance-updated')
        this.is_recalculating = false
      } catch ({ response }) {
        this.serverError()
      }
    },

    async lockBreakdown(breakdown) {
      try {
        breakdown.is_locked
          ? await this.$http.delete(
              `locked-allowance-breakdowns/${breakdown.id}`,
              {
                data: { company_id: this.activeCompany.id },
              }
            )
          : await this.$http.put(
              `locked-allowance-breakdowns/${breakdown.id}`,
              {
                company_id: this.activeCompany.id,
              }
            )

        this.$emit('employment-allowance-updated')

        this.success(
          'Carry over has been ' +
            (breakdown.is_locked ? 'unlocked' : 'locked') +
            ' for recalculation.'
        )
      } catch ({ response }) {
        this.serverError()
      }
    },
  },
}
</script>
<style>
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
